import { AbstractControl } from "@angular/forms";
import * as moment from "moment";

import {
  formatDateForBackend,
  getMinutesBetweenTimes,
} from "../_helpers/utils";
import { Attachment, AttachmentDTO } from "./attachment";
import { CommonObject, CommonObjectDTO } from "./common-object";
import { Employee, EmployeeDTO } from "./employee";
import { Org, OrgDTO } from "./org";
import {
  EducationUnit,
  EducationUnitDTO,
  Service,
  ServiceDTO,
} from "./service";

export interface PlannedUnitDTO extends CommonObjectDTO {
  start?: Date;
  end?: Date;
  location?: string;
  education_unit?: EducationUnitDTO;
  unit_id?: number;
  presences: PresenceDTO[];
  teachers: TeacherDTO[];
  resources: ResourceDTO[];
  planned_courses: PlannedCourseDTO[];
  attachments: AttachmentDTO[];
  participants_count: number;
  note?: string;
}

export class PlannedUnit extends CommonObject {
  start?: Date;
  end?: Date;
  location?: string;
  educationUnit?: EducationUnit;
  presences?: Presence[];
  plannedCourses?: PlannedCourse[];
  teachers?: Teacher[];
  resources?: Resource[];
  attachments?: Attachment[];
  participantsCount?: number;
  note?: string;

  constructor(dto: PlannedUnitDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      if (dto.start) {
        this.start = new Date(dto.start);
      }
      if (dto.end) {
        this.end = new Date(dto.end);
      }
      this.location = dto.location;
      this.participantsCount = dto.participants_count;
      this.note = dto.note;
      if (dto.education_unit) {
        this.educationUnit = new EducationUnit(dto.education_unit);
        this.addLoadedRelation("education_unit");
      }
      if (dto.presences) {
        this.presences = dto.presences.map(
          (presence) => new Presence(presence)
        );
        this.addLoadedRelation("presences");
      }
      if (dto.teachers) {
        this.teachers = dto.teachers.map((teacher) => new Teacher(teacher));
        this.addLoadedRelation("teachers");
      }
      if (dto.resources) {
        this.resources = dto.resources.map(
          (resource) => new Resource(resource)
        );
        this.addLoadedRelation("resources");
      }
      if (dto.attachments) {
        this.attachments = dto.attachments.map(
          (attachment) => new Attachment(attachment)
        );
        this.addLoadedRelation("attachments");
      }
      if (dto.planned_courses) {
        this.plannedCourses = dto.planned_courses.map(
          (plannedCourse) => new PlannedCourse(plannedCourse)
        );
        this.addLoadedRelation("planned_courses");
      }
    }
  }

  public toDTO(): PlannedUnitDTO {
    let result: PlannedUnitDTO = <PlannedUnitDTO>super.toDTO();
    Object.assign(result, {
      unit_id: this.educationUnit ? this.educationUnit.objectId : null,
      start: formatDateForBackend(this.start, true),
      end: formatDateForBackend(this.end, true),
      presences: this.presences
        ? this.presences.map((presence) => presence.toDTO())
        : [],
      teachers: this.teachers
        ? this.teachers.map((teacher) => teacher.toDTO())
        : [],
      attachments: this.attachments
        ? this.attachments.map((attachment) => attachment.toDTO())
        : [],
      resources: this.resources
        ? this.resources.map((resource) => resource.toDTO())
        : [],
      location: this.location,
      participants_count: this.participantsCount,
      note: this.note,
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: PlannedUnit
  ): PlannedUnit {
    const formModel = formGroup.value;
    let startDate;
    if (formModel.startTime) {
      startDate = moment(formModel.date);
      let splits = formModel.startTime.split(":");
      startDate.set({
        hours: splits[0],
        minutes: splits[1],
      });
    }
    let endDate;
    if (formModel.endTime) {
      endDate = moment(formModel.date);
      let splits = formModel.endTime.split(":");
      endDate.set({
        hours: splits[0],
        minutes: splits[1],
      });
    }
    let plannedUnit: PlannedUnit = new PlannedUnit(null);
    plannedUnit.objectId = formModel.objectId;
    plannedUnit.educationUnit = formModel.educationUnit;
    plannedUnit.start = startDate;
    plannedUnit.end = endDate;
    plannedUnit.location = formModel.location;
    plannedUnit.note = formModel.note;
    if (original) {
      plannedUnit.objectId = original.objectId;
      plannedUnit.loadedRelations = original.loadedRelations;
    }
    return plannedUnit;
  }
  get recovers(): Presence[] {
    return this.presences ? this.presences.filter((p) => !!p.recovers) : [];
  }

  get recoversCount(): number {
    return this.recovers.length;
  }

  get startTime(): string {
    return this.start ? moment(this.start).format("HH:mm").toString() : "";
  }

  get endTime(): string {
    return this.end ? moment(this.end).format("HH:mm").toString() : "";
  }

  get cost(): number {
    return this.teachersCost + this.resourcesCost;
  }

  get resourcesCost(): number {
    if (this.resources) {
      return this.resources
        .map((r) => r.cost)
        .reduce((acc, value) => (value ? acc + value : acc), 0);
    }
    return 0;
  }

  get teachersCost(): number {
    if (this.teachers) {
      let minutes = getMinutesBetweenTimes(this.startTime, this.endTime);
      let halfs = Math.round(minutes / 30);
      return this.teachers
        .map((t) => t.hourlyRate)
        .reduce((acc, value) => (value ? acc + (value * halfs) / 2 : acc), 0);
    }
    return 0;
  }
}

export interface ResourceDTO extends CommonObjectDTO {
  type?: string;
  description?: string;
  cost?: number;
  planned_unit?: PlannedUnitDTO;
  planned_unit_id?: number;
}

export class Resource extends CommonObject {
  type?: string;
  description?: string;
  cost?: number;
  plannedUnit?: PlannedUnit;

  constructor(dto: ResourceDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.type = dto.type;
      this.description = dto.description;
      this.cost = dto.cost;
      if (dto.planned_unit) {
        this.plannedUnit = new PlannedUnit(dto.planned_unit);
        this.addLoadedRelation("planned_unit");
      }
    }
  }

  public toDTO(): ResourceDTO {
    let result: ResourceDTO = <ResourceDTO>super.toDTO();
    Object.assign(result, {
      planned_unit_id: this.plannedUnit ? this.plannedUnit.objectId : null,
      type: this.type,
      description: this.description,
      cost: this.cost,
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Resource
  ): Resource {
    const formModel = formGroup.value;
    let resource: Resource = new Resource(null);
    resource.objectId = formModel.objectId;
    resource.plannedUnit = formModel.plannedUnit;
    resource.type = formModel.type;
    resource.description = formModel.description;
    resource.cost = formModel.cost;
    if (original) {
      resource.objectId = original.objectId;
      resource.loadedRelations = original.loadedRelations;
    }
    return resource;
  }
}

export interface ParticipantDTO extends CommonObjectDTO {
  employee?: EmployeeDTO;
  employee_id?: number;
  partner?: OrgDTO;
  partner_id?: number;
  planned_course?: PlannedCourseDTO;
  planned_course_id?: number;
  paid_amount?: number;
  paid_executed?: boolean;
  invoiceable?: boolean;
  completed?: boolean;
  presences?: PresenceDTO[];
  certificate_send?: Date;
  has_certificate?: boolean;
  certificate_note?: string;
  certificate_type?: string;
  certificate_subtitle?: string;
  note?: string;
  orderId?: string;
}

export class Participant extends CommonObject {
  employee?: Employee;
  partner?: Org;
  plannedCourse?: PlannedCourse;
  paidAmount?: number;
  paidExecuted?: boolean;
  invoiceable?: boolean;
  completed?: boolean;
  presences?: Presence[];
  certificateSend?: Date;
  note?: string;
  orderId: string;
  hasCertificate?: boolean;
  certificateNote?: string;
  certificateType?: string;
  certificateSubtitle?: string;

  constructor(dto: ParticipantDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.paidAmount = dto.paid_amount;
      this.paidExecuted = dto.paid_executed;
      this.invoiceable = dto.invoiceable;
      this.note = dto.note;
      if (dto.employee) {
        this.employee = new Employee(dto.employee, ["attachments"]);
        this.addLoadedRelation("employee");
      }
      if (dto.partner) {
        this.partner = new Org(dto.partner);
        this.addLoadedRelation("partner");
      }
      if (dto.planned_course) {
        this.plannedCourse = new PlannedCourse(dto.planned_course);
        this.addLoadedRelation("planned_course");
      }
      if (dto.presences) {
        this.presences = dto.presences.map(
          (presence) => new Presence(presence)
        );
        this.addLoadedRelation("presences");
      }
      if (dto.certificate_send) {
        this.certificateSend = new Date(dto.certificate_send);
      }
      this.completed = dto.completed;
      this.hasCertificate = dto.has_certificate;
      this.certificateNote = dto.certificate_note;
      this.orderId = dto.orderId;
      this.certificateType = dto.certificate_type;
      this.certificateSubtitle = dto.certificate_subtitle;
    }
  }

  public toDTO(): ParticipantDTO {
    let result: ParticipantDTO = <ParticipantDTO>super.toDTO();
    Object.assign(result, {
      employee_id: this.employee ? this.employee.objectId : null,
      partner_id: this.partner ? this.partner.objectId : null,
      planned_course_id: this.plannedCourse
        ? this.plannedCourse.objectId
        : null,
      paid_amount: this.paidAmount,
      paid_executed: this.paidExecuted,
      invoiceable: this.invoiceable,
      completed: this.completed,
      certificate_send: formatDateForBackend(this.certificateSend),
      has_certificate: this.hasCertificate,
      certificate_note: this.certificateNote,
      certificate_type: this.certificateType,
      certificate_subtitle: this.certificateSubtitle,
      note: this.note,
      orderId: this.orderId,
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Participant
  ): Participant {
    const formModel = formGroup.value;
    let participant: Participant = new Participant(null);
    participant.objectId = formModel.objectId;
    participant.plannedCourse = formModel.plannedCourse;
    participant.employee = formModel.employee;
    participant.partner = formModel.partner;
    participant.paidAmount = formModel.paidAmount;
    participant.paidExecuted = formModel.paidExecuted;
    participant.invoiceable = formModel.invoiceable;
    participant.hasCertificate = formModel.hasCertificate;
    participant.certificateNote = formModel.certificateNote;
    participant.orderId = formModel.orderId;
    participant.certificateType = formModel.certificateType;
    participant.certificateSubtitle = formModel.certificateSubtitle;
    participant.note = formModel.note;
    if (original) {
      participant.objectId = original.objectId;
      participant.loadedRelations = original.loadedRelations;
    }
    return participant;
  }

  get presencesHours(): number {
    if (this.presences) {
      return this.presences
        .map((presence) => presence.hours)
        .reduce((sum, presence) => {
          return sum + presence;
        }, 0);
    }
    return 0;
  }

  get sentTooltip(): string {
    if (this.certificateSend) {
      return `Attestato inviato il ${moment(this.certificateSend).format(
        "DD-MM-YYYY HH:mm"
      )}`;
    }
    return "";
  }
}

export enum PlannedCourseStatusEnum {
  "draft" = "Bozza",
  "planned" = "Pianificato",
  "completed" = "Concluso",
  "cancelled" = "Annullato",
}

export interface PlannedCourseDTO extends CommonObjectDTO {
  org?: OrgDTO;
  org_id?: number;
  org_paid_amount?: number;
  org_paid_executed?: boolean;
  service?: ServiceDTO;
  service_id?: number;
  parent?: PlannedCourseDTO;
  parent_id?: number;
  status?: PlannedCourseStatusEnum;
  childs?: PlannedCourseDTO[];
  note?: string;
  planned_units?: PlannedUnitDTO[];
  resources?: ResourceDTO[];
  participants?: ParticipantDTO[];
  attachments?: AttachmentDTO[];
}

export class PlannedCourse extends CommonObject {
  service?: Service;
  org?: Org;
  orgPaidExecuted?: boolean;
  orgPaidAmount?: number;
  status?: PlannedCourseStatusEnum;
  parent?: PlannedCourse;
  childs?: PlannedCourse[];
  note?: string;
  plannedUnits?: PlannedUnit[];
  resources?: Resource[];
  participants?: Participant[];
  attachments?: Attachment[];

  constructor(dto: PlannedCourseDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.note = dto.note;
      this.orgPaidExecuted = dto.org_paid_executed;
      this.orgPaidAmount = dto.org_paid_amount;
      this.status = dto.status;
      if (dto.service) {
        this.service = new Service(dto.service);
        this.addLoadedRelation("service");
      }
      if (dto.org) {
        this.org = new Org(dto.org);
        this.addLoadedRelation("org");
      }
      if (dto.parent) {
        this.parent = new PlannedCourse(dto.parent);
        this.addLoadedRelation("parent");
      }
      if (dto.planned_units) {
        this.plannedUnits = dto.planned_units.map(
          (planned_unit) => new PlannedUnit(planned_unit)
        );
        this.addLoadedRelation("planned_units");
      }
      if (dto.childs) {
        this.childs = dto.childs.map((child) => new PlannedCourse(child));
        this.addLoadedRelation("childs");
      }
      if (dto.resources) {
        this.resources = dto.resources.map(
          (resource) => new Resource(resource)
        );
        this.addLoadedRelation("resources");
      }
      if (dto.participants) {
        this.participants = dto.participants.map(
          (participant) => new Participant(participant)
        );
        this.addLoadedRelation("participants");
      }
      if (dto.attachments) {
        this.attachments = dto.attachments.map(
          (attachment) => new Attachment(attachment)
        );
        this.addLoadedRelation("attachments");
      }
    }
  }

  public toDTO(): PlannedCourseDTO {
    let result: PlannedCourseDTO = <PlannedCourseDTO>super.toDTO();
    Object.assign(result, {
      service_id: this.service ? this.service.objectId : null,
      org_id: this.org ? this.org.objectId : null,
      note: this.note,
      org_paid_executed: this.orgPaidExecuted,
      org_paid_amount: this.orgPaidAmount,
      parent_id: this.parent ? this.parent.objectId : null,
      planned_units: this.plannedUnits
        ? this.plannedUnits.map((plannedUnit) => plannedUnit.toDTO())
        : [],
      childs: this.childs ? this.childs.map((child) => child.toDTO()) : [],
      participants: this.participants
        ? this.participants.map((participant) => participant.toDTO())
        : [],
      attachments: this.attachments
        ? this.attachments.map((attachment) => attachment.toDTO())
        : [],
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: PlannedCourse
  ): PlannedCourse {
    const formModel = formGroup.value;
    let plannedCourse: PlannedCourse = new PlannedCourse(null);

    plannedCourse.service = formModel.service;
    plannedCourse.note = formModel.note;
    plannedCourse.org = formModel.org;
    plannedCourse.orgPaidAmount = formModel.orgPaidAmount;
    plannedCourse.orgPaidExecuted = formModel.orgPaidExecuted;

    if (original) {
      plannedCourse.objectId = original.objectId;
      plannedCourse.loadedRelations = original.loadedRelations;
      plannedCourse.parent = formModel.parent;
      plannedCourse.childs = formModel.childs;
    }
    return plannedCourse;
  }

  get recoversCount(): number {
    const presences: Presence[] = [].concat(
      ...this.plannedUnits.map((p) => p.recovers)
    );
    const x: Set<number> = new Set<number>();
    presences.forEach((presence) => {
      if (
        presence.recovers &&
        presence.recovers.participant &&
        presence.recovers.participant.employee
      ) {
        x.add(presence.recovers.participant.employee.objectId);
      }
    });
    return x.size;
  }
  get recovers(): Presence[] {
    return [].concat(...this.plannedUnits.map((p) => p.recovers));
  }

  get startDate(): Date {
    return this.plannedUnits && this.plannedUnits.length > 0
      ? Math.min.apply(
          Math,
          this.plannedUnits.map((plannedUnit) => plannedUnit.start)
        )
      : null;
  }

  get endDate(): Date {
    return this.plannedUnits && this.plannedUnits.length > 0
      ? Math.max.apply(
          Math,
          this.plannedUnits.map((plannedUnit) => plannedUnit.start)
        )
      : null;
  }

  get childsIncome(): number {
    if (this.childs) {
      return this.childs
        .map((c) => c.courseIncome)
        .reduce((acc, value) => (value ? acc + value : acc), 0);
    }
    return 0;
  }

  get courseIncome(): number {
    if (this.participants) {
      return (
        this.participants
          .map((p) => p.paidAmount)
          .reduce((acc, value) => (value ? acc + value : acc), 0) +
        this.orgPaidAmount
      );
    }
    return 0;
  }

  get plannedUnitsCost(): number {
    if (this.plannedUnits) {
      return this.plannedUnits
        .map((p) => p.cost)
        .reduce((acc, value) => (value ? acc + value : acc), 0);
    }
    return 0;
  }

  get total(): number {
    return this.courseIncome + this.childsIncome - this.plannedUnitsCost;
  }
}

export type RecoveryStatus = "billable" | "billed" | "paid";
export interface RecoveryDetailsDTO {
  cost: number;
  status: RecoveryStatus;
}

export interface PresenceDTO extends CommonObjectDTO {
  participant?: ParticipantDTO;
  participant_id?: number;
  start?: Date;
  end?: Date;
  planned_unit?: PlannedUnitDTO;
  planned_unit_id?: number;
  recovery_id?: number;
  recovery?: PresenceDTO;
  recovers?: PresenceDTO;
  absence?: boolean;
  success?: boolean;
  note?: string;
  recovery_details: RecoveryDetailsDTO;
}

export class Presence extends CommonObject {
  start?: Date;
  end?: Date;
  participant?: Participant;
  plannedUnit?: PlannedUnit;
  recovery?: Presence;
  recovers?: Presence;
  absence?: boolean;
  success?: boolean;
  note?: string;
  recoveryDetails: RecoveryDetailsDTO;

  constructor(dto: PresenceDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.note = dto.note;
      this.absence = dto.absence;
      this.success = dto.success;
      if (dto.start) {
        this.start = new Date(dto.start);
      }
      if (dto.end) {
        this.end = new Date(dto.end);
      }
      if (dto.participant) {
        this.participant = new Participant(dto.participant);
        this.addLoadedRelation("participant");
      }
      if (dto.recovery) {
        this.recovery = new Presence(dto.recovery);
        this.addLoadedRelation("recovery");
      }
      if (dto.recovers) {
        this.recovers = new Presence(dto.recovers);
        this.addLoadedRelation("recovers");
      }
      if (dto.planned_unit) {
        this.plannedUnit = new PlannedUnit(dto.planned_unit);
        this.addLoadedRelation("planned_unit");
      }
      this.recoveryDetails = dto.recovery_details;
    }
  }

  public toDTO(): PresenceDTO {
    let result: PresenceDTO = <PresenceDTO>super.toDTO();
    Object.assign(result, {
      planned_unit_id: this.plannedUnit ? this.plannedUnit.objectId : null,
      participant_id: this.participant ? this.participant.objectId : null,
      recovery_id: this.recovery ? this.recovery.objectId : null,
      note: this.note,
      absence: this.absence,
      success: this.success,
      start: formatDateForBackend(this.start, true),
      end: formatDateForBackend(this.end, true),
      recovery_details: this.recoveryDetails,
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Presence
  ): Presence {
    const formModel = formGroup.value;
    let startDate;
    if (formModel.startTime) {
      startDate = moment(formModel.date);
      let splits = formModel.startTime.split(":");
      startDate.set({
        hours: splits[0],
        minutes: splits[1],
      });
    }
    let endDate;
    if (formModel.endTime) {
      endDate = moment(formModel.date);
      let splits = formModel.endTime.split(":");
      endDate.set({
        hours: splits[0],
        minutes: splits[1],
      });
    }
    let presence: Presence = new Presence(null);
    presence.objectId = formModel.objectId;
    presence.start = startDate;
    presence.end = endDate;
    presence.participant = formModel.participant;
    presence.plannedUnit = formModel.plannedUnit;
    presence.recovery = formModel.recovery;
    presence.absence = !formModel.attendance;
    presence.success = formModel.success;
    presence.note = formModel.note;
    presence.recoveryDetails = {
      cost: formModel.recoveryCost,
      status: formModel.recoveryStatus,
    };

    if (original) {
      presence.objectId = original.objectId;
      presence.loadedRelations = original.loadedRelations;
    }
    return presence;
  }

  get hours(): number {
    if (this.absence) return 0;
    const result = moment(this.end).diff(moment(this.start), "hours", true);
    // console.log("Start: ", this.start);
    // console.log("End: ", this.start);
    // console.log("Hours: ", result);
    return result;
  }
}

export interface TeacherDTO extends CommonObjectDTO {
  type?: string;
  name?: string;
  surname?: string;
  hourly_rate?: number;
  qualification?: string;
  planned_unit?: PlannedUnitDTO;
  planned_unit_id?: number;
  exclude_from_certificate?: boolean;
}

export class Teacher extends CommonObject {
  type?: string;
  name?: string;
  surname?: string;
  hourlyRate?: number;
  qualification?: string;
  plannedUnit?: PlannedUnit;
  excludeFromCertificate?: boolean;

  constructor(dto: TeacherDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.type = dto.type;
      this.name = dto.name;
      this.surname = dto.surname;
      this.hourlyRate = dto.hourly_rate;
      this.qualification = dto.qualification;
      this.excludeFromCertificate = dto.exclude_from_certificate;
      if (dto.planned_unit) {
        this.plannedUnit = new PlannedUnit(dto.planned_unit);
        this.addLoadedRelation("planned_unit");
      }
    }
  }

  public toDTO(): TeacherDTO {
    let result: TeacherDTO = <TeacherDTO>super.toDTO();
    Object.assign(result, {
      planned_unit_id: this.plannedUnit ? this.plannedUnit.objectId : null,
      type: this.type,
      name: this.name,
      surname: this.surname,
      hourly_rate: this.hourlyRate,
      qualification: this.qualification,
      exclude_from_certificate: this.excludeFromCertificate,
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Teacher
  ): Teacher {
    const formModel = formGroup.value;
    let teacher: Teacher = new Teacher(null);
    teacher.objectId = formModel.objectId;
    teacher.plannedUnit = formModel.plannedUnit;
    teacher.type = formModel.type;
    teacher.name = formModel.name;
    teacher.surname = formModel.surname;
    teacher.hourlyRate = formModel.hourlyRate;
    teacher.qualification = formModel.qualification;
    teacher.excludeFromCertificate = formModel.excludeFromCertificate;
    if (original) {
      teacher.objectId = original.objectId;
      teacher.loadedRelations = original.loadedRelations;
    }
    return teacher;
  }
}

export interface PresenceFilters {
  search_query?: string;
  start?: Date;
  end?: Date;
  unitIds?: number[];
  fatturabile?: AbsenceEnumFilter[];
  saldato?: AbsenceEnumFilter[];
}

export enum AbsenceEnumFilter {
  All = "all",
  Yes = "yes",
  No = "no",
}
