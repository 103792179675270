import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../_helpers/listResult.interface';
import { Org } from '../_models/org';
import { PlannedCourse } from '../_models/planning';
import { Service } from '../_models/service';
import { Participant, PlannedCourseStatusEnum } from './../_models/planning';
import { LaravelParticipantService } from './laravel/laravel-participant.service';
import { LaravelPlannedCourseService } from './laravel/laravel-plannedCourse.service';

@Injectable({ providedIn: "root" })
export class PlanningService {
  constructor(
    private laravelPlannedCourseService: LaravelPlannedCourseService,
    private laravelParticipantService: LaravelParticipantService
  ) { }

  public addPlannedCourse(
    plannedCourse: PlannedCourse
  ): Observable<PlannedCourse> {
    let unitAttachments: Map<number, any[]> = new Map<number, any[]>();
    if (plannedCourse.plannedUnits) {
      plannedCourse.plannedUnits.forEach((plannedUnit, index) => {
        unitAttachments.set(index, plannedUnit.attachments.map(a => a.file));
      });
    }
    return this.laravelPlannedCourseService
      .createPlannedCourse(
        plannedCourse.toDTO(),
        plannedCourse.attachments.map(a => a.file),
        unitAttachments
      )
      .pipe(
        map(dto => {
          return new PlannedCourse(dto);
        })
      );
  }

  public updatePlannedCourse(
    plannedCourse: PlannedCourse
  ): Observable<PlannedCourse> {
    let unitAttachments: Map<number, any[]> = new Map<number, any[]>();
    if (plannedCourse.plannedUnits) {
      plannedCourse.plannedUnits.forEach((plannedUnit, index) => {
        unitAttachments.set(index, plannedUnit.attachments.map(a => a.file));
      });
    }
    return this.laravelPlannedCourseService
      .updatePlannedCourse(
        plannedCourse.objectId,
        plannedCourse.toDTO(),
        plannedCourse.attachments.map(a => a.file),
        unitAttachments
      )
      .pipe(
        map(dto => {
          return new PlannedCourse(dto);
        })
      );
  }

  public completePlannedCourse(
    plannedCourse: PlannedCourse,
    includes?: string[]
  ): Observable<PlannedCourse> {
    return this.laravelPlannedCourseService
      .completePlannedCourse(
        plannedCourse.objectId,
        includes ? includes : plannedCourse.loadedRelations
      )
      .pipe(
        map(dto => {
          return new PlannedCourse(dto);
        })
      );
  }

  public cancelPlannedCourse(
    plannedCourse: PlannedCourse
  ): Observable<PlannedCourse> {
    return this.laravelPlannedCourseService
      .cancelPlannedCourse(
        plannedCourse.objectId,
        plannedCourse.loadedRelations
      )
      .pipe(
        map(dto => {
          return new PlannedCourse(dto);
        })
      );
  }

  public restorePlannedCourse(
    plannedCourse: PlannedCourse
  ): Observable<PlannedCourse> {
    return this.laravelPlannedCourseService
      .restorePlannedCourse(
        plannedCourse.objectId,
        plannedCourse.loadedRelations
      )
      .pipe(
        map(dto => {
          return new PlannedCourse(dto);
        })
      );
  }

  public archivePlannedCourse(
    plannedCourse: PlannedCourse
  ): Observable<PlannedCourse> {
    return this.laravelPlannedCourseService
      .deletePlannedCourse(plannedCourse.objectId)
      .pipe(
        map(() => {
          return plannedCourse;
        })
      );
  }

  public clonePlannedCourse(
    plannedCourseId: number,
    include?: string | string[]
  ): Observable<PlannedCourse> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelPlannedCourseService
      .clone(plannedCourseId, includes)
      .pipe(map(dto => new PlannedCourse(dto)));
  }

  public getPlannedCourse(
    plannedCourseId: number,
    include?: string | string[]
  ): Observable<PlannedCourse> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelPlannedCourseService
      .getPlannedCourseById(plannedCourseId, includes)
      .pipe(map(dto => new PlannedCourse(dto)));
  }

  public getPlannedCourses(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: PlannedCourseFilters,
    include?: string | string[]
  ): Observable<ListResult<PlannedCourse>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelPlannedCourseService
      .getPlannedCourses(page, perPage, order, direction, filter, includes)
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new PlannedCourse(dto)),
            total: response.total
          };
        })
      );
  }

  public getPlannableServices(
    plannedCourse: PlannedCourse
  ): Observable<Service[]> {
    // if (plannedUnits && plannedUnits.length > 0) {
    //   return this.laravelPlannedCourseService
    //     .getPlannableServices(
    //       plannedUnits.map(planned_unit => planned_unit.objectId)
    //     )
    //     .pipe(map(dtos => dtos.map(dto => new Service(dto))));
    // } else {
    //   return of(null);
    // }
    return this.laravelPlannedCourseService
      .getPlannableServices(plannedCourse.objectId)
      .pipe(map(dtos => dtos.map(dto => new Service(dto))));
  }

  public planPlannableServices(
    plannedCourse: PlannedCourse,
    plannableServices: Service[]
  ): Observable<PlannedCourse[]> {
    if (plannableServices && plannableServices.length > 0) {
      return this.laravelPlannedCourseService
        .planPlannableServices(
          plannedCourse.objectId,
          plannableServices.map(service => service.objectId)
        )
        .pipe(map(dtos => dtos.map(dto => new PlannedCourse(dto))));
    } else {
      return of(null);
    }
  }

  public dowloadRegistry(
    plannedCourse: PlannedCourse,
    emptyLines: number,
    partner?: Org,
  ): Observable<Blob> {
    return this.laravelPlannedCourseService
      .downloadRegistry(
        plannedCourse.objectId,
        partner ? partner.objectId : null,
        emptyLines
      )
      .pipe(map(data => new Blob([data], { type: "application/pdf" })));
  }

  public moveParticipants(
    sourceId: number,
    destinationId: number,
    participantIds: number[],
    include?: string | string[]
  ): Observable<PlannedCourse> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelPlannedCourseService
      .moveParticipants(sourceId, destinationId, participantIds, includes)
      .pipe(map(dto => new PlannedCourse(dto)));
  }

  public generateCertificate(participant: Participant): Observable<{ data: Blob, filename?: string }> {
    return this.laravelParticipantService
      .generateCertificate(participant.objectId).pipe(map(response => {
        return {
          data: new Blob([response.data], { type: "application/pdf" }),
          filename: response.filename
        }
      }))

  }

  public generateMultipleCertificates(participantIds: number[]): Observable<Blob> {
    return this.laravelParticipantService
      .generateMultipleCertificates(participantIds)
      .pipe(map(data => new Blob([data], { type: "application/x-zip" })));
  }

  public exportParticipants(plannedCourseId: number): Observable<Blob> {
    return this.laravelPlannedCourseService
      .exportParticipants(plannedCourseId)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }

  public importParticipants(plannedCourseId: number, orgId: number, csv: any): Observable<Participant[]> {
    return this.laravelPlannedCourseService
      .importParticipants(plannedCourseId, orgId, csv)
      .pipe(map(dtos => dtos.map(dto => new Participant(dto))));
  }

  public areParticipantsBusy(employeeIds: number[], dates: Date[], excludedUnitIds: number[]): Observable<number[]> {
    return this.laravelParticipantService.isBusy(employeeIds, dates, excludedUnitIds);
  }

  public downloadFile(id: number): Observable<Blob> {
    return this.laravelPlannedCourseService
      .downloadFile(id)
      .pipe(map(data => new Blob([data])));
  }

  public plannedCourseBillingData(plannedCourseId: number): Observable<Blob> {
    return this.billingData(null, null, null, [plannedCourseId]);
  }

  public billingData(from: Date, to: Date, statuses: string[], ids?: number[]): Observable<Blob> {
    return this.laravelPlannedCourseService
      .billingData(from, to, statuses, ids)
      .pipe(map(data => new Blob([data])));
  }
}

export class PlannedCourseFilters {
  query?: string;
  statuses?: PlannedCourseStatusEnum[];
}
