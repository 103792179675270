import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDateForBackend } from 'app/_helpers/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ListResultDTO } from '../../_helpers/listResult.interface';
import { ParticipantDTO, PlannedCourseDTO } from '../../_models/planning';
import { ServiceDTO } from '../../_models/service';
import { PlannedCourseFilters } from './../planning.service';

@Injectable({ providedIn: "root" })
export class LaravelPlannedCourseService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/plannedCourse",
      list: environment["laravel"]["serverUrl"] + "/api/plannedCourses",
      store: environment["laravel"]["serverUrl"] + "/api/plannedCourse",
      delete: environment["laravel"]["serverUrl"] + "/api/plannedCourse",
      clone: environment["laravel"]["serverUrl"] + "/api/plannedCourse/clone",
      plannable_services:
        environment["laravel"]["serverUrl"] + "/api/plannable_services",
      plan_plannable_services:
        environment["laravel"]["serverUrl"] + "/api/plan_plannable_services",
      complete:
        environment["laravel"]["serverUrl"] + "/api/plannedCourse/complete",
      cancel: environment["laravel"]["serverUrl"] + "/api/plannedCourse/cancel",
      restore:
        environment["laravel"]["serverUrl"] + "/api/plannedCourse/restore",
      generate_registry:
        environment["laravel"]["serverUrl"] + "/api/plannedCourse/registry",
      move_participants:
        environment["laravel"]["serverUrl"] + "/api/move_participants",
      export_participants:
        environment["laravel"]["serverUrl"] + "/api/export_participants",
      import_participants:
        environment["laravel"]["serverUrl"] + "/api/import_participants",
      download:
        environment["laravel"]["serverUrl"] + "/api/download",
      billingData:
        environment["laravel"]["serverUrl"] + "/api/plannedCourse/billing_data",
    };
  }

  getPlannedCourseById(
    id: number,
    include?: string[]
  ): Observable<PlannedCourseDTO> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<PlannedCourseDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getPlannedCourses(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: PlannedCourseFilters,
    include?: string[]
  ): Observable<ListResultDTO<PlannedCourseDTO>> {
    if (per_page) {
      return this.getPaginatedResults(
        page,
        per_page,
        order,
        direction,
        filter,
        include
      );
    } else {
      return this.getAllResults(order, direction, filter, include).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  private getPaginatedResults(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filter?: PlannedCourseFilters,
    include?: string[]
  ): Observable<ListResultDTO<PlannedCourseDTO>> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.statuses) params["statuses[]"] = filter.statuses;
      if (filter.query) params["search_query"] = filter.query;
    }

    return this.httpClient.get<ListResultDTO<PlannedCourseDTO>>(
      this.ROUTES.list,
      {
        params: new HttpParams({ fromObject: params })
      }
    );
  }

  private getAllResults(
    order: string,
    direction: string,
    filter?: PlannedCourseFilters,
    include?: string[]
  ): Observable<PlannedCourseDTO[]> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filter) {
      if (filter.statuses) params["statuses[]"] = filter.statuses;
      if (filter.query) params["search_query"] = filter.query;
    }
    return this.httpClient.get<PlannedCourseDTO[]>(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public createPlannedCourse(
    dto: PlannedCourseDTO,
    attachments?: any[],
    unit_attachments?: Map<number, any[]>
  ): Observable<PlannedCourseDTO> {
    let formData: FormData = new FormData();
    formData.append("planned_course", JSON.stringify(dto));
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment);
      });
    }
    if (unit_attachments) {
      unit_attachments.forEach((attachments, unit_index) => {
        attachments.forEach((attachment, index) => {
          formData.append(`unit_${unit_index}_attachment_${index}`, attachment);
        });
      });
    }
    return this.httpClient.post<PlannedCourseDTO>(this.ROUTES.store, formData);
  }

  public updatePlannedCourse(
    id: number,
    dto: PlannedCourseDTO,
    attachments?: any[],
    unit_attachments?: Map<number, any[]>
  ): Observable<PlannedCourseDTO> {
    dto.id = id;
    let formData: FormData = new FormData();
    formData.append("planned_course", JSON.stringify(dto));
    if (attachments) {
      attachments.forEach((attachment, index) => {
        formData.append(`attachment_${index}`, attachment);
      });
    }
    if (unit_attachments) {
      unit_attachments.forEach((attachments, unit_index) => {
        attachments.forEach((attachment, index) => {
          formData.append(`unit_${unit_index}_attachment_${index}`, attachment);
        });
      });
    }
    return this.httpClient.post<PlannedCourseDTO>(this.ROUTES.store, formData); //mrosetti - Using PUT instead of POST will break the entire request, don't do it
  }

  public deletePlannedCourse(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public clone(id: number, includes?: string[]): Observable<PlannedCourseDTO> {
    return this.httpClient.post<PlannedCourseDTO>(this.ROUTES.clone, {
      id: id,
      includes: includes
    });
  }

  public completePlannedCourse(id: number, includes?: string[]) {
    return this.httpClient.put(this.ROUTES.complete, {
      id: id,
      includes: includes
    });
  }

  public cancelPlannedCourse(id: number, includes?: string[]) {
    return this.httpClient.put(this.ROUTES.cancel, {
      id: id,
      includes: includes
    });
  }

  public restorePlannedCourse(id: number, includes?: string[]) {
    return this.httpClient.put(this.ROUTES.restore, {
      id: id,
      includes: includes
    });
  }

  public getPlannableServices(
    planned_course_id: number
  ): Observable<ServiceDTO[]> {
    let params = {};
    if (planned_course_id) params["planned_course_id"] = planned_course_id;
    return this.httpClient.get<ServiceDTO[]>(this.ROUTES.plannable_services, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public planPlannableServices(
    planned_course_id: number,
    service_ids: number[]
  ): Observable<PlannedCourseDTO[]> {
    return this.httpClient.post<PlannedCourseDTO[]>(
      this.ROUTES.plan_plannable_services,
      {
        planned_course_id: planned_course_id,
        service_ids: service_ids
      }
    );
  }

  public downloadRegistry(
    planned_course_id: number,
    partner_id: number,
    empty_lines: number,
  ): Observable<Blob> {
    let params = {
      planned_course_id: "" + planned_course_id,
      empty_lines: "" + empty_lines
    };
    if (partner_id) {
      params["partner_id"] = "" + partner_id;
    }
    return this.httpClient.get(this.ROUTES.generate_registry, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public moveParticipants(
    source_course_id: number,
    destination_course_id: number,
    participant_ids: number[],
    includes?: string[]
  ): Observable<PlannedCourseDTO> {
    return this.httpClient.put<PlannedCourseDTO>(
      this.ROUTES.move_participants,
      {
        source_course_id: source_course_id,
        destination_course_id: destination_course_id,
        participant_ids: participant_ids,
        includes: includes
      }
    );
  }

  public exportParticipants(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.export_participants, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public importParticipants(id: number, org_id: number, participants: any): Observable<ParticipantDTO[]> {
    let formData: FormData = new FormData();
    formData.append("id", "" + id);
    formData.append("org_id", "" + org_id);
    formData.append("participants", participants);

    return this.httpClient.post<ParticipantDTO[]>(this.ROUTES.import_participants, formData);
  }

  public downloadFile(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.download, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public billingData(from: Date, to: Date, statuses: string[], ids: number[]): Observable<Blob> {
    let params = {};
    if (from) {
      params["from"] = formatDateForBackend(from);
    }
    if (to) {
      params["to"] = formatDateForBackend(to);
    }
    if (statuses && statuses.length > 0) {
      params["statuses[]"] = statuses;
    }
    if (ids && ids.length > 0) {
      params["ids[]"] = ids;
    }

    return this.httpClient.get(this.ROUTES.billingData, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }
}
